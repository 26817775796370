@font-face {
  font-family: 'Gotham';
  src: url('fonts/GothamBlack.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
  
@font-face {
  font-family: 'GothamBold';
  src: url('fonts/GothamUltraItalic.otf') format('truetype');
  font-weight: bolder;
  font-style: normal;
}
  
@font-face {
  font-family: 'GothamLight';
  src: url('fonts/GothamLight.otf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'GothamBook';
  src: url('fonts/GothamBook.otf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

.open {
  display: block;
}

.closed {
  display: none;
}

html, body {
  width: 100%;
  height: 100%;
  margin:0px;
  background: linear-gradient(to bottom, #050644, #000000);
  background-attachment: fixed;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

h1 {
  font-size:1.8vh;
  padding-top:3vh;
  padding-bottom:4vh;
  margin:0;
  color:#FFF;
  text-align:center;
  font-family: GothamBold;
  font-weight: bolder;
}

header p {
  color:#fff;
  font-family: GothamLight;
  font-style: italic;
  font-size:1.2vh;
  text-align: center;
  display:inline-block;
  width:100%;
  margin:4vh 0;
}

.openOptions {
  border:none;
  background-color: transparent;
  font-size:3vh;
  position:absolute;
  float:left;
  left:7vw;
  top:3vh;
  padding:0;
  cursor:pointer;
  color: #3C5CFF;
}

.toggle-container {
  position:absolute;
  float:right;
  right:7.5vw;
  top:3vh;
  width:5.5vh;
  height:3.2vh;
  background-color: #ffffff50;
  cursor:pointer;
  border-radius:5vh;
  user-select:none;
  padding:0;
}

.toggle-button {
  display:flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width:3.2vh;
  height:100%;
  background-color: #3C5CFF;
  color:#fff;
  border-radius: 5vh;
  font-size: 1.1vh;
  font-family: Gotham;
  position:absolute;
}

.mi {
  right:0vh;
  left:auto;
}

.km {
  left:0vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.slider::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.slider {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.slider {
  width:100vw;
  margin:0;
  margin-bottom:4vh;
  display: flex;
  justify-content: left;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.slider .champ {
  margin-right: 2vh;
  display: inline-block;
}

.slider .champ:first-child {
  margin-right: 2vh;
  margin-left: 7.5vw;
  display: inline-block;
}

.slider .champ img {
  border-radius: 100%;
  box-sizing: border-box;
  border:#3C5CFF 3px solid;
  width:10vh;
  height:10vh;
  cursor:pointer;
}

.slider .champ p {
  margin:1vh 0;
  color:#FFF;
  text-align:center;
  font-size: 9px;
  font-family: GothamBook;
}

.Field {
  display:inline-table;
  width:85vw;
  margin:0 7.5vw;
  height:11.5vh;
  max-width:500px;
}

.Speed {
  height:6vh;
}

h2 {
  color:#FFF;
  text-align:left;
  font-family: Gotham;
  font-size:2.8vh;
  font-weight: bold;
  display:inline;
  cursor:text;
}

input {
  box-sizing: border-box;
  outline: none !important;
  border-radius: 0;
  border-style:none;
  border:none;
  background-color:transparent;
  text-align:right;
  color:#3C5CFF;
  font-family: Gotham;
  font-size:max(2.8vh, 16px);
  width:40vw;
  padding:0;
}

input::placeholder {
  color:#3c5cff57;
}

input:focus {
  outline:none;
}

.unit {
  font-size:max(2vh, 14px);
  display:inline;
  color:#fff;
  font-family: GothamLight;
  font-weight:lighter;
  text-align: right;
  margin:0;
  float:right;
}

.Distance .unit {
  width:50vw;
}

.shortcuts {
  margin-top:1.2vh;
}

.shortcut {
  background-color: #3C5CFF;
  color:#fff;
  font-family: GothamBook;
  font-size:1.2vh;
  padding:0.9vh 1.5vh;
  cursor: pointer;
  border-radius:2vh;
  border-style: none;
  margin:0 0 0 8px;
  float:right;
}

.actions {
  width:85vw;
  margin:2vh 0vw 1vh 7.5vw;
  display: flex;
  justify-content: space-between;
}

.Button {
  border:none;
  font-family: Gotham;
  font-size: 2vh;
  border-radius: 0.5vh;
  padding:5vw;
  background-color: #3C5CFF;
  color:#fff;
  display:inline-block;
  cursor: pointer;
  width:48%;
  height:5vh;
  padding:0;
}

.overlay {
  position: fixed;
  top:0;
  height:100%;
  width:100%;
  backdrop-filter: blur(10px);
  }

.sharingOptions {
  background: linear-gradient(to bottom, #050644, #000000);
  width:100%;
  position: fixed;
  bottom:0vh;
  padding-bottom: 6vh;
 }

.sharingOptions .cross {
  color:#bbbbbb;
  background-color: #ffffff1a;
  height: 8vw;
  width: 8vw;
  border-radius: 8vw;
  margin:2vh 0 2vh 90vw;
  cursor:pointer;
}

.sharingOptions .cross svg {
 width: 50%;
 height: 50%;
 margin: 25%;
}

.sharingOptions .Button {
  width:90vw;
  margin:2vh 5vw;
}

.sharingOptions p {
  margin: 0;
  color: #fff;
  font-family: Gotham;
  font-size: 14px;

  text-align: center;
}

.disabled {
  background-color:#29292931;
  color:#303030;
  cursor:not-allowed;
}

.affiliationBlock {
  width:85vw;
  margin:0 7.5vw;
  max-width:500px;
}

.affiliationCTA1 {
  border:none;
  font-family: Gotham;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  background:linear-gradient(to right, #8420CA, #FF9344);
  text-decoration: none;
  color:#fff;
  padding:1.5vh 0;
  width:50vw;
  margin: 3vh auto 3vh auto;
  display:block;
  cursor: pointer;
  text-align: center;
}

.affiliationBlock .secondary {
  background:linear-gradient(to right, #8420CA, #FF9344);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
  text-align: center;
  font-family: Gotham;
}

.affiliationCTA2 {
  display:inline-block;
  width:100%;
  text-align: center;
  font-size: 1.8vh;
}

.affiliationBlock img {
  width:33.33%;
  box-sizing: border-box;
  border: transparent 3px solid;
  display: inline-flex;
  justify-content: space-between;
}

.affiliationBlock p {
  text-align: center;
  color:#FFF;
  font-family: GothamBook;
  font-size: 1.4vh;
  padding:0;
  margin:4vh 0 2vh 0;
  width:100%;
}

.contentBlock {
  margin-top:8vh;
  margin-left:7.5vw;
  width:85vw;
  padding-top: 8vh;
}

.contentBlock h2 {
  font-size:1.7vh;
  color:#ffffff;
}

.contentBlock p {
  color:#ffffff;
  font-family: GothamBook;
  font-size:1.5vh;
  padding-bottom: 3vh;
  text-align: justify;
}

.openOptions svg {
  font-size:3vh;
}

@media (min-width: 700px) {
  
  h1 {
    padding-top:6vh;
    font-size:3vh;
    line-height: 3vh;
  }

  header p {
    font-size:1.5vh;
  }

  .slider {
    display:flex;
    justify-content: center;
    width:600px;
    max-width:600px;
    margin:2vh auto 4vh auto;
    overflow:scroll;
    cursor:grab;
  }

  .slider .champ {
    margin: 0 2vh;
  }

  .slider .champ:first-child {
    margin: 0 2vh 0 0;
  }

  .Field {
    display:block;
    width:600px;
    max-width:600px;
    margin:0 auto;
  }

  .Speed {
    height:8vh;
  }

  .container {
    width:600px;
    display: block;
    margin:0 auto;
  }

  input {
    width:40%;
  }

  .Distance .unit {
    width:60%;
  }

  .shortcut{
    margin:0 0 0 10px;
  }

  .toggle-container {
    position:relative;
    float:right;
    right:0;
  }

  .openOptions {
    position:relative;
    float:left;
    left:0;
  }

  .overlay {
    width:100%;
    left:0;
  }

  .sharingOptions {
    width:100%;
    max-width: 600px;
    margin:0 auto;
  }

  .sharingOptions .Button {
    width:60%;
    margin-left:20%;
  }

  .sharingOptions .cross {
    margin:2% 10% 2% 90%;
    height:36px;
    width:36px;
  }

  .actions {
    width:60%;
    max-width:600px;
    margin:0 auto;
  }

  .affiliationBlock {
    width:60%;
    max-width:600px;
    margin:0 auto;
  }

  .affiliationCTA1 {
    width:60%;
    max-width:600px;
  }

  .contentBlock {
    padding-top:6vh;
    width:600px;
    display: block;
    margin:6vh auto;
  }
}